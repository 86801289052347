<template>
    <workbenchbox
        v-loading="loading"
        :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner"
        :element-loading-background="config.loading.background"
    >   
        <div class="dp-f pt-12">
            <!-- <choose style="height:calc(100vh - 112px)" class="mr-20 choose" @Emit="butemit" :Data="[{title:'业务章'}]"></choose> -->
            <service ></service>
            <!-- <general v-if="state.butIndex==1"></general> -->
        </div>
    </workbenchbox>
</template>
<script setup>
import { reactive,ref,unref } from 'vue'
import choose from "@/components/sidebar/choose.vue"
import workbenchbox from "@/components/layout/workbenchbox.vue";
import service from "./components/service.vue"//业务章
import general from "./components/general.vue"//总章
const loading = ref(false);//loading 显示
const state = reactive({
  butIndex:0,//按钮选中下标
})
// 按钮返回
const butemit=((el)=>{
    state.butIndex=el
    // console.log('按钮返回',el)
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
.choose{
    min-height: 750px;
}
</style>